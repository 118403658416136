<template>
  <div class="">
    <trac-loading v-if="isLoading" />
    <main class="md:ml-12 mr-4 md:mr-0" v-if="loadedAssets">
      <trac-back-button>Back</trac-back-button>
      <trac-modal
        v-if="sendInvoiceModalCtrl && currentUserBusinessDetails"
        class="max-w-sm w-full"
        @close="sendInvoiceModalCtrl = false"
      >
        <span class="font-bold text-sm">Send Invoice</span>
        <trac-validation-observer slim v-slot="{ handleSubmit }">
          <form class="mt-10" @submit.prevent="handleSubmit(sendInvoice)">
            <trac-input
              class="my-6"
              placeholder="From"
              disabled
              v-model.trim="currentUserBusinessDetails.email"
            ></trac-input>
            <div class="my-6">
              <trac-validation-provider
                name="emails"
                rules="required"
                v-slot="{ errors }"
              >
                <trac-input
                  placeholder="To (emails)"
                  v-model.trim="sendInvoiceInfo.recipients"
                ></trac-input>
                <trac-input-error v-if="errors[0]">{{
                  errors[0]
                }}</trac-input-error>
              </trac-validation-provider>
            </div>
            <trac-input
              class="my-6"
              placeholder="Subject"
              v-model.trim="sendInvoiceInfo.subject"
            ></trac-input>
            <trac-textarea
              class="my-6"
              placeholder="Message"
              v-model.trim="sendInvoiceInfo.message"
            ></trac-textarea>

            <div class="my-6 font-semibold text-sm">
              <trac-toggle
                @newval="sendInvoiceInfo.sendToMyself = !$event"
                :active="sendInvoiceInfo.sendToMyself"
                v-model="sendInvoiceInfo.sendToMyself"
                >Send a copy to myself</trac-toggle
              >
            </div>
            <div class="flex justify-end">
              <trac-button
                :disabled="webIsOffline"
                class="ml-4"
                @button-clicked="sendInvoiceModalCtrl = false"
                >Cancel</trac-button
              >
              <trac-button :disabled="webIsOffline" class="ml-4" type="submit"
                >Confirm</trac-button
              >
            </div>
          </form>
        </trac-validation-observer>
      </trac-modal>

      <trac-modal
        v-if="recordPaymentModalCtrl"
        @close="recordPaymentModalCtrl = false"
      >
        <trac-validation-observer slim v-slot="{ handleSubmit }">
          <form class="mt-4" @submit.prevent="handleSubmit(makePaymentRecord)">
            <span class="font-bold text-base">Record Payment</span>
            <trac-validation-provider
              name="payment option"
              rules="required"
              v-slot="{ errors }"
            >
              <trac-dropdown-exteneded
                class="my-4"
                :neededProperty="'name'"
                :options="paymentOptions"
                :selector="'Select Payment'"
                @optionSelected="selectPaymentOption($event)"
                v-model="paymentRecord.name"
              ></trac-dropdown-exteneded>
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>

            <trac-validation-provider
              name="amount"
              rules="required|positive|greaterthanzero"
              v-slot="{ errors }"
            >
              <trac-input
                class="my-4"
                v-model.number="paymentRecord.amount"
                placeholder="Amount"
              />
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>
            <trac-dropdown-exteneded
              :neededProperty="''"
              :selector="paymentRecordDate"
              :customDropdown="true"
              :inputDate="new Date(paymentRecord.date)"
              class=""
              @selectDate="paymentRecord.date = new Date($event).toISOString()"
            ></trac-dropdown-exteneded>

            <trac-button
              :disabled="webIsOffline"
              buttonType="submit"
              class="mt-8 w-full"
              >Add Record</trac-button
            >
          </form>
        </trac-validation-observer>
      </trac-modal>

      <trac-modal :cancel="false" v-if="requestPaymentModalCtrl">
        <form @submit.prevent="requestPayment" class="mt-4">
          <trac-custom-header>
            <template slot="leading">Request</template>
            <template slot="trailing"> Payment</template>
          </trac-custom-header>
          <div
            class="mt-2"
            v-if="(authAgentDetails.payment_option || []).includes('online_payment')"
          >
            <trac-radio
              name="fulfillment-stage"
              :selectedVal="requestPaymentOption"
              :value="'online_payment'"
              @change="requestPaymentOption = $event"
              >Online Payment</trac-radio
            >
          </div>
          <div
            class="mt-2"
            v-if="(authAgentDetails.payment_option || []).includes('bank_transfer')"
          >
            <trac-radio
              name="fulfillment-stage"
              :selectedVal="requestPaymentOption"
              :value="'bank_transfer'"
              @change="requestPaymentOption = $event"
              >Dynamic Account</trac-radio
            >
          </div>
          <div
            class="mt-2"
            v-if="(authAgentDetails.payment_option || []).includes('own_bank_account')"
          >
            <trac-radio
              name="fulfillment-stage"
              :selectedVal="requestPaymentOption"
              :value="'own_bank_account'"
              @change="requestPaymentOption = $event"
              >Bank Transfer</trac-radio
            >
          </div>

          <div class="mt-8 flex justify-end">
            <trac-button
              :disabled="webIsOffline"
              class="mx-2"
              @button-clicked="
                requestPaymentOption = '';
                requestPaymentModalCtrl = false;
              "
              >Cancel</trac-button
            >
            <trac-button
              :disabled="webIsOffline"
              class="mx-2"
              buttonType="submit"
              >Confirm</trac-button
            >
          </div>
        </form>
      </trac-modal>

      <div class="grid md:flex flex-row md:justify-between">
        <ul class="flex-col mt-12 md:mb-24">
          <li><a href="#">Invoices</a></li>
          <li class="font-bold mt-4 text-sm">Invoice Details</li>
        </ul>
        <ul class="grid md:flex flex-row mt-6 md:mt-32 mb-8 gap-5 items-end">
          <li>
            <trac-button
              :disabled="webIsOffline"
              @button-clicked="scrollInvoiceToView"
              variant="outline"
              class="uppercase w-full"
            >
              Preview Invoice</trac-button
            >
          </li>
          <li v-if="userPermissions.editInvoice">
            <trac-button
              :disabled="webIsOffline"
              variant="outline"
              class="uppercase w-full"
              @button-clicked="editInvoice"
            >
              Edit Invoice</trac-button
            >
          </li>
          <li v-if="userPermissions.deleteInvoice">
            <trac-button
              :disabled="webIsOffline"
              @button-clicked="deleteInvoice"
              variant="outline"
              class="uppercase text-secondaryRed border-secondaryRed w-full"
            >
              Delete Invoice
            </trac-button>
          </li>
        </ul>
      </div>
      <div class="grid md:flex flex-row gap-5">
        <div class="flex-column md:w-6/12">
          <div class="bg-white px-8 pt-8 rounded-lg overflow-hidden big-shadow">
            <span class="text-sm font-semibold">
              Commercial Invoice (INV #{{
                invoice.sn | serialNumberFormat
              }})</span
            >
            <ul class="mt-8 mb-8">
              <div class="flex flex-row justify-between">
                <div class="flex-column md:w-3/12">
                  <span class="text-xs text-primaryGray">Customer:</span>
                </div>
                <div class="flex-column">
                  <span
                    @click="gotoCustomerDetails"
                    class="text-primaryBlue underline capitalize text-xs font-semibold cursor-pointer"
                    >{{
                      invoice.customer.first_name +
                      " " +
                      invoice.customer.last_name
                    }}
                  </span>
                </div>
              </div>
              <div class="flex flex-row justify-between">
                <div class="flex-column md:w-3/12">
                  <span class="text-xs text-primaryGray">Source Store:</span>
                </div>
                <div class="flex-column">
                  <span class="text-xs font-semibold capitalize">{{
                    invoice.store_name
                  }}</span>
                </div>
              </div>
              <div class="flex flex-row justify-between">
                <div class="flex-column md:w-3/12">
                  <span class="text-xs text-primaryGray">Invoice amount:</span>
                </div>
                <div class="flex-column">
                  <span class="text-xs font-semibold">{{
                    invoiceAmount | formatPrice(false)
                  }}</span>
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div class="flex-column md:w-6/12">
          <div
            class="bg-white px-8 py-8 h-full rounded-lg overflow-hidden big-shadow"
          >
            <div class="flex flex-row">
              <div class="flex-column w-6/12">
                <ul>
                  <li class="text-xs text-primaryGray">Invoice Status</li>
                  <li class="flex flex-row text-xs font-semibold">
                    <img class="mr-2" src="../../assets/svg/Sent.svg" />{{
                      invoiceDue === 0
                        ? "Paid"
                        : invoiceDue > 0 && invoiceAmount > invoiceDue
                        ? "Partially Paid"
                        : invoice.invoice_status
                    }}
                  </li>
                </ul>
              </div>
              <div class="flex-column w-6/12">
                <ul>
                  <li class="text-xs text-primaryGray">Order Status</li>
                  <li class="flex flex-row text-xs font-semibold capitalize">
                    <img class="mr-2" src="../../assets/svg/Fulfilled.svg" />{{
                      invoice.order_status
                    }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="flex flex-row mt-10">
              <div class="flex-column w-6/12">
                <ul>
                  <li class="text-xs text-primaryGray">Amount Due</li>
                  <li class="text-sm font-bold">
                    {{ invoiceDue | formatPrice(true) }}
                  </li>
                </ul>
              </div>
              <div class="flex-column w-6/12">
                <ul>
                  <li class="text-xs text-primaryGray">Due Date</li>
                  <li class="text-sm font-bold">
                    {{ moment(invoice.due_date).format("ddd MMM DD YYYY") }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="grid md:flex flex-row gap-5">
        <div class="flex flex-row mt-5 mb-5 md:w-6/12">
          <div
            class="bg-white w-full px-8 py-8 rounded-lg overflow-scroll big-shadow"
          >
            <span class="text-sm font-semibold">Products</span>
            <table class="w-full table-fixed mt-4">
              <thead class="border-b">
                <tr class="text-left text-xs mt-3 font-bold h-10">
                  <th class="md:w-3/12">Item</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Discount</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody class="mt-3">
                <tr
                  class="text-left text-xs mt-3 h-10"
                  v-for="(item, i) in invoice.items"
                  :key="i"
                >
                  <td class="capitalize">{{ item.name }}</td>
                  <td>{{ item.quantity }}</td>
                  <td>{{ item.unit_price | formatPrice }}</td>
                  <td>{{ (discountedAmount || 0) | formatPrice }}</td>
                  <td>{{ ((item.unit_price * item.quantity) - discountedAmount) | formatPrice }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="md:w-6/12" v-if="invoice.Payments">
          <div
            class="flex flex-row mt-5 mb-5"
            v-if="invoice.Payments.length > 0"
          >
            <div
              class="bg-white w-full px-8 py-8 rounded-lg overflow-hidden big-shadow"
            >
              <span class="text-sm font-semibold">Payments</span>
              <table class="w-full table-fixed mt-4">
                <thead class="border-b">
                  <tr class="text-left text-xs mt-3 font-bold h-10">
                    <th>Payment Method</th>
                    <th>Amount</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody class="mt-3">
                  <tr
                    class="text-left text-xs mt-3 h-10"
                    v-for="(payment, i) in invoice.Payments"
                    :key="i"
                  >
                    <td class="capitalize">
                      {{ payment.name.split("_").join(" ") }}
                    </td>
                    <td>{{ payment.amount | formatPrice }}</td>
                    <td>
                      {{
                        moment(new Date(payment.date)).format("Do ddd MMM YYYY")
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="flex justify-end mt-4">
                <p class="text-gray-700 text-sm">
                  Total Amount Paid:
                  <span class="font-bold">{{
                    totalPaymentRecords | formatPrice
                  }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-row md:w-6/12 mt-5 mb-5" v-if="invoice.milestones">
        <div
          class="bg-white w-full px-8 py-8 rounded-lg overflow-hidden big-shadow"
        >
          <span class="text-sm font-semibold">Milestones</span>
          <table class="w-full table-fixed mt-4">
            <thead class="border-b">
              <tr class="text-left text-xs mt-3 font-bold h-10">
                <th>Name</th>
                <th>Amount</th>
                <th>Due Date</th>
                <th class=""></th>
              </tr>
            </thead>
            <tbody class="mt-3">
              <tr
                class="text-left text-xs mt-3 h-10"
                v-for="(milestone, i) in invoice.milestones"
                :key="i"
              >
                <td class="capitalize">{{ milestone.title }}</td>
                <td>{{ milestone.amount | formatPrice }}</td>
                <td>
                  {{ moment(milestone.due_date).format("Do ddd MMM YYYY") }}
                </td>
                <td class="capitalize">
                  <trac-checkbox
                    :isChecked="milestone.status === 'fulfilled'"
                    @check="checkMilestone($event, milestone)"
                  ></trac-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="grid md:flex flex-row gap-5">
        <div class="flex-column md:w-6/12">
          <div class="bg-white px-8 py-8 rounded-lg overflow-hidden big-shadow">
            <span class="text-sm font-semibold">Order Fulfillment</span>
            <div class="grid gap-4 md:flex flex-row mt-8">
              <trac-radio
                name="fulfillment-stage"
                :selectedVal="invoice.order_status"
                :value="'unfulfilled'"
                @change="changeFulfillmentValue"
                >Unfulfilled</trac-radio
              >
              <trac-radio
                name="fulfillment-stage"
                :selectedVal="invoice.order_status"
                :value="'fulfilled'"
                @change="changeFulfillmentValue"
                >Fulfilled</trac-radio
              >
              <trac-radio
                name="fulfillment-stage"
                :selectedVal="invoice.order_status"
                :value="'partially fulfilled'"
                @change="changeFulfillmentValue"
                >Partially Fulfilled</trac-radio
              >
            </div>
            <div class="md:flex flex-row mt-8 md:justify-end">
              <trac-button
              v-if="userPermissions.editInvoice"
                :disabled="webIsOffline"
                variant="small"
                @button-clicked="updateFufillment"
                >Update Fulfillment</trac-button
              >
            </div>
          </div>
        </div>
        <div class="flex-column md:w-6/12">
          <div
            class="bg-white px-8 py-8 h-full rounded-lg overflow-hidden big-shadow"
          >
            <span class="text-sm font-semibold">Notes</span>
            <div class="mt-6">
              <span class="text-primaryGray text-xs" v-if="invoice.notes">{{
                invoice.notes.text === ""
                  ? "No note was added"
                  : invoice.notes.text
              }}</span>
              <span class="text-primaryGray text-xs" v-else>{{
                "No note was added"
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8 ml-auto">
        <div class="grid md:flex flex-row gap-5 md:justify-end">
          <trac-button
          v-if="userPermissions.resendInvoice"
            :disabled="webIsOffline"
            class="uppercase"
            @button-clicked="sendInvoiceModalCtrl = true"
            >{{ invoice.sent ? "Resend" : "Send" }} Invoice</trac-button
          >
          <trac-button
          v-if="userPermissions.requestPayment"
            :disabled="webIsOffline"
            @button-clicked="openRequestPayment"
            class="uppercase"
            >Request Payment</trac-button
          >
          <trac-button
          v-if="userPermissions.editInvoice"
            class="uppercase"
            @button-clicked="recordPaymentModalCtrl = true"
            :disabled="totalPaymentRecords === invoiceAmount || webIsOffline"
            >Record Payment</trac-button
          >
        </div>
      </div>
      <div class="flex flex-row">
        <div
          class="bg-white px-8 py-8 w-full mt-8 rounded-lg overflow-hidden big-shadow"
        >
          <div
            class="bg-accentLight px-8 py-12 w-full rounded-lg overflow-hidden"
          >
            <div class="grid gap-4 md:flex flex-row items-center md:justify-between">
              <div class="flex-column w-4/12">
                <img
                  class="business-logo max-w-sm"
                  :src="invoicePreview.business.logo"
                />
              </div>
              <div class="flex-column md:w-4/12 text-center">
                <span
                  class="my-auto text-primaryBlue font-bold text-xs uppercase"
                  >{{ invoice.title === "" ? "Invoice" : invoice.title }}</span
                >
              </div>
              <div class="flex-column w-4/12 text-left">
                <div class="float-right">
                  <span class="font-semibold text-primaryBlue text-sm">{{
                    currentUserBusinessDetails.name
                  }}</span>
                  <div>
                    <ul class="text-primaryGray text-xs">
                      <li class="text-xs">
                        {{ currentUserBusinessDetails.address.street }}
                      </li>
                      <li class="text-xs">
                        {{ currentUserBusinessDetails.address.city }}
                      </li>
                      <li class="text-xs">
                        {{ currentUserBusinessDetails.address.state }}
                      </li>
                      <li class="text-xs">
                        {{ currentUserBusinessDetails.address.country }}
                      </li>
                      <li class="text-xs">
                        {{ currentUserBusinessDetails.phone }}
                      </li>
                      <li class="text-primaryBlue underline">
                        <span class="cursor-pointer" @click="openBusinessPortal"
                          >Edit Business Details</span
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid gap-4 md:flex flex-row mt-8 ml-5 md:justify-between">
            <div class="flex-column md:w-3/12">
              <ul class="text-primaryGray text-xs">
                <li class="uppercase">Bill To:</li>
                <li class="uppercase mt-2 font-semibold text-black">
                  {{
                    invoice.customer.first_name +
                    " " +
                    invoice.customer.last_name
                  }}
                </li>
                <li class="mt-3">{{ invoice.customer.address.street }}</li>
                <li>{{ invoice.customer.address.city }}</li>
                <li>{{ invoice.customer.address.state }}</li>
                <li>{{ invoice.customer.address.country }}</li>
              </ul>
            </div>
            <div class="flex-column md:w-4/12">
              <div class="d">
                <ul class="flex flex-row justify-between md:justify-start">
                  <li class="text-xs md:w-5/12">Invoice Number</li>
                  <li class="text-xs text-primaryGray uppercase">
                    Inv{{ invoice.sn | serialNumberFormat }}
                  </li>
                </ul>
                <ul class="flex flex-row justify-between md:justify-start">
                  <li class="text-xs w-5/12">Invoice Date</li>
                  <li class="text-xs text-primaryGray text-right">
                    {{ moment(invoice.created_at).format("ddd MMM DD YYYY") }}
                  </li>
                </ul>
                <ul class="flex flex-row justify-between md:justify-start">
                  <li class="text-xs md:w-5/12">Due Date</li>
                  <li class="text-xs text-primaryGray">
                    {{ moment(invoice.due_date).format("ddd MMM DD YYYY") }}
                  </li>
                </ul>
                <ul class="flex flex-row justify-between md:justify-start">
                  <li class="text-xs md:w-5/12">Payment Terms</li>
                  <li class="text-xs text-primaryGray">
                    {{ invoice.type.description }}
                  </li>
                </ul>
                <ul class="flex flex-row mt-6 justify-between md:justify-start">
                  <li class="text-xs text-primaryBlue uppercase md:w-5/12">
                    Amount Due
                  </li>
                  <li class="text-xs text-primaryGray">
                    {{ invoiceDue | formatPrice(false) }}
                    <!-- invoice.balance.outstanding.amount | formatPrice(false) -->
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="mx-5 my-8 border rounded-lg overflow-scroll">
            <table class="w-full table-auto">
              <thead class="bg-blue-100" id="invoice-preview">
                <tr class="text-left text-xs mt-3 font-semibold uppercase h-12">
                  <th class="md:w-7/12 md:pl-8 whitespace-no-wrap px-4 text-center">Item</th>
                  <th class="whitespace-no-wrap px-4 text-center">Quantity</th>
                  <th class="whitespace-no-wrap px-4 text-center">Price</th>
                  <th>Discount</th>
                  <th class="whitespace-no-wrap px-4 text-center">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="text-left text-xs mt-3 h-12"
                  v-for="(item, i) in invoice.items"
                  :key="i"
                >
                  <td class="capitalize md:pl-8  whitespace-no-wrap px-4 text-center">{{ item.name }}</td>
                  <td class="whitespace-no-wrap px-4 text-center">{{ item.quantity }}</td>
                  <td class="whitespace-no-wrap px-4 text-center">{{ item.unit_price | formatPrice }}</td>
                  <td>{{ (discountedAmount || 0) | formatPrice }}</td>
                  <td class="whitespace-no-wrap px-4 text-center">{{ ((item.unit_price * item.quantity) - discountedAmount) | formatPrice }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mr-6 ml-6 border-b pb-6 text-right">
            <span class="font-semibold text-xs uppercase"
              >Amount Due
              <span class="text-primaryGray text-xs ml-5 whitespace-no-wrap">{{
                invoiceDue | formatPrice(false)
              }}</span>
            </span>
          </div>
          <div class="ml-6 mr-6 mt-8 border-b pb-12" v-if="invoicePreview">
            <span class="font-semibold text-sm">How to pay this invoice</span>
            <div class="flex flex-row mt-8">
              <div
                class="flex-column w-5/12"
                v-if="
                  (invoice.payment_method || []).includes('online_payment') ||
                  invoice.payment_option === 'both'
                "
              >
                <div class="flex flex-row">
                  <div class="flex-column w-3/12">
                    <img src="../../assets/svg/card.svg" />
                  </div>
                  <div class="flex-column ml-10">
                    <span class="font-semibold text-sm">Pay with card</span>
                    <div class="font-light text-xs">
                      You can pay this invoice online or using your card by
                      clicking on this link below
                    </div>
                    <a
                      target="_blank"
                      :href="invoicePreview.payLink"
                      class="text-xs font-bold text-primaryBlue uppercase mt-4"
                    >
                      Make payment online
                    </a>
                  </div>
                </div>
              </div>

              <div
                class="flex-column w-5/12 ml-10"
                v-if="(invoice.payment_method || []).includes('bank_transfer')"
              >
                <div class="flex flex-row">
                  <div class="flex-column w-3/12">
                    <img src="../../assets/svg/bank.svg" />
                  </div>
                  <div class="flex-column ml-8">
                    <span class="font-semibold text-sm"
                      >Pay using Bank transfer</span
                    >
                    <div class="font-light text-xs">
                      Pay this invoice directly by bank transfer with the
                      following details:
                    </div>
                    <div
                      class="text-xs font-bold text-primaryBlue uppercase mt-4"
                    >
                      <span v-if="invoicePreview.business">{{
                        invoicePreview.accountName2
                      }}</span>
                      <br />
                      {{ invoicePreview.bankNumber2 }}
                      <br />
                      {{ invoicePreview.settlementBank2 }}
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="flex-column w-5/12 ml-10"
                v-if="
                  (invoice.payment_method || []).includes('own_bank_account') ||
                  invoice.payment_option === 'bank transfer' ||
                  invoice.payment_option === 'both'
                "
              >
                <div class="flex flex-row">
                  <div class="flex-column w-3/12">
                    <img src="../../assets/svg/bank.svg" />
                  </div>
                  <div class="flex-column ml-8">
                    <span class="font-semibold text-sm"
                      >Pay using Bank transfer</span
                    >
                    <div class="font-light text-xs">
                      Pay this invoice directly by bank transfer with the
                      following details:
                    </div>
                    <div
                      class="text-xs font-bold text-primaryBlue uppercase mt-4"
                    >
                      <span v-if="invoicePreview.business">{{
                        invoicePreview.business.name
                      }}</span>
                      <br />
                      {{ invoicePreview.bankNumber }}
                      <br />
                      {{ invoicePreview.settlementBank }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-8 ml-6">
            <span class="font-semibold text-sm">Notes</span>
            <div class="text-xs text-primaryGray mt-6" v-if="invoice.notes">
              {{
                invoice.notes.text === ""
                  ? "No note was added"
                  : invoice.notes.text
              }}
            </div>
            <div class="text-xs text-primaryGray mt-6" v-else>
              {{ "No note was added" }}
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {
  DELETE_LOCAL_DB_DATA,
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_DATA,
  GET_USER_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";
import RequestPayment from "./RequestPayment";
import moment from "moment";
import { HTTP_REQUEST } from "./../../http-config/trac-http-req";
import { eventBus } from "./../../main";
import { permissionsModules } from "./../settings/permission";

export default {
  name: "InvoiceDetails",
  components: { RequestPayment },
  data() {
    return {
      isLoading: false,
      userPermissions: null,
      makePayAction: false,
      sendInvoiceModalCtrl: false,
      recordPaymentModalCtrl: false,
      requestPaymentModalCtrl: false,
      invoice: null,
      invoicePreview: null,
      requestPaymentOption: "",
      customers: null,
      currentUserBusinessDetails: null,
      authAgentDetails: null,
      customer: null,
      loadedAssets: false,
      moment,
      sendInvoiceInfo: {
        recipients: "",
        subject: "",
        message: "",
        sendToMyself: false,
      },
      paymentRecord: {
        name: "",
        amount: "",
        date: new Date().toISOString(),
        curremcy: "NGN",
      },
    };
  },
  async created() {
    this.isLoading = true;
    this.userPermissions =
        permissionsModules[1]["parent"]["permissionsForUser"];
    const invoice = GET_LOCAL_DB_DATA("invoice-details");
    this.invoice = { Payments: [], ...invoice };

    await this.fetchAllCustomers();
    await this.collateCustomerBalance();
    await this.fetchInvoice(invoice._id);
    this.getBusinessDetails();
    await this.fetchInvoiceDetails();
    this.loadedAssets = true;
    this.sendInvoiceInfo.subject = `Invoice from ${this.currentUserBusinessDetails.name}`;
    this.selectCustomer(invoice.customer || this.customer);
    this.isLoading = false;
  },
  destroyed() {
    SAVE_LOCAL_DB_DATA("invoice-details", this.invoice);
  },
  beforeRouteEnter(to, from, next) {
    if (GET_LOCAL_DB_DATA("invoice-details")) {
      next();
    } else {
      next({ name: "Invoices" });
    }
  },
  computed: {
    sumInvoiceProducts() {
      return this.invoice
        ? this.invoice.items.reduce(
            (total, item) => total + item.unit_price * item.quantity,
            0
          )
        : 0;
    },
    totalPaymentRecords() {
      return (this.invoice.Payments || []).reduce(
        (total, payment) => total + payment.amount,
        0
      );
    },
    paymentRecordDate() {
      return new Date(this.paymentRecord.date).toLocaleDateString();
    },
    webIsOffline() {
      return !window.navigator.onLine;
    },
    paymentOptions() {
      const paymentOptionsRegister = [];
      (this.authAgentDetails.payment_option || []).forEach((payment) => {
        if (payment === "online_payment" || payment === "bank_transfer") {
          paymentOptionsRegister.push({
            payment_method: payment,
            name: payment.split("_").join(" "),
          });
        } else if (payment === "own_bank_account") {
          paymentOptionsRegister.push({
            payment_method: payment,
            name: "Bank Transfer (Dynamic)",
          });
        }
      });

      return paymentOptionsRegister;
    },
    invoiceAmount() {
      return this.invoice.balance.total.amount
        ? this.invoice.balance.total.amount
        : 0;
    },
    invoiceDue() {
      return this.invoiceAmount
        ? this.invoiceAmount - this.totalPaymentRecords
        : 0;
    },
    discountedAmount() {
      return this.invoice.discount.discounted_amount
    }
  },
  methods: {
    openBusinessPortal() {
      SAVE_LOCAL_DB_DATA("invoice-details-for-profile-update", this.invoice);
      this.$router.push({ name: "CompanyInfo" });
    },
    scrollInvoiceToView() {
      const element = document.getElementById("invoice-preview");
      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    },
    selectPaymentOption(option) {
      this.paymentRecord.name = option.payment_method;
    },
    async checkMilestone(e, milestone) {
      this.isLoading = true;
      const payload = {
        invoiceID: this.invoice._id,
        milestone: {
          ...milestone,
          status: e.checked ? "fulfilled" : "unfulfilled",
        },
      };

      await this.$store.dispatch("UPDATE_MILESTONE", payload);
      const res = this.$store.getters["GET_INVOICE_RES"];

      this.$store.commit("SET_UP_INVOICE_RES", null);

      if (res.status) {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
    async updateFufillment() {
      this.isLoading = true;
      await this.$store.dispatch("UPDATE_INVOICE_FULFILLMENT", this.invoice);
      const res = this.$store.getters["GET_INVOICE_RES"];

      this.$store.commit("SET_UP_INVOICE_RES", null);

      if (res.status) {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
    async requestPayment() {
      this.isLoading = true;
      const payload = {
        invoice_id: this.invoice._id,
        payment_method: this.requestPaymentOption,
      };
      await this.$store.dispatch("REQUEST_INVOICE_PAYMENT", payload);
      const res = this.$store.getters["GET_INVOICE_RES"];

      this.$store.commit("SET_UP_INVOICE_RES", null);

      if (res.status) {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
        this.requestPaymentOption = "";
        this.requestPaymentModalCtrl = false;
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
    selectCustomer(option) {
      this.invoice.client = option._id;
      this.sendInvoiceInfo.recipients = option.email;
      this.sendInvoiceInfo.message = `Hi, ${
        option.first_name + " " + option.last_name
      }.\nPlease find attached invoice for a total of ${this.formatPrice(
        this.sumInvoiceProducts
      )}.\nThank you.\n\n${this.currentUserBusinessDetails.name}.`;
    },
    // Format balance
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async sendInvoice() {
      this.isLoading = true;
      // Setup emails
      let emails = this.sendInvoiceInfo.recipients.split(",");

      emails = emails.map((email) => email.trim());

      for (let i = 0; i < emails.length; i++) {
        const invoiceEmail = {
          invoice: this.invoice._id,
          email: emails[i],
          subject:
            this.sendInvoiceInfo.subject === ""
              ? `Invoice from ${this.currentUserBusinessDetails.name}`
              : this.sendInvoiceInfo.subject,
          note: this.sendInvoiceInfo.message,
          // payment_option: this.invoice.payment_method,
          payment_option: "",
          send_to_self: this.sendInvoiceInfo.sendToMyself,
        };
        await this.$store.dispatch("SEND_INVOICE", invoiceEmail);
      }

      const sendRes = this.$store.getters["GET_INVOICE_RES"];
      this.$store.commit("SET_UP_INVOICE_RES", null);

      // alert(sendRes.message);
      eventBus.$emit("trac-alert", { message: sendRes.message });
      if (sendRes.status) {
        this.sendInvoiceModalCtrl = false;
        // const invoice = GET_LOCAL_DB_DATA("invoice-details");
        this.invoice.invoice_status = "Sent";
        // await this.fetchInvoice(invoice._id);
        // await this.fetchInvoiceDetails();
      }

      this.isLoading = false;
    },
    async makePaymentRecord() {
      this.makePayAction = true;

      this.isLoading = true;
      const payload = {
        invoiceID: this.invoice._id,
        body: this.paymentRecord,
      };

      await this.$store.dispatch("UPDATE_PAYMENT", payload);
      const res = this.$store.getters["GET_INVOICE_RES"];

      this.$store.commit("SET_UP_INVOICE_RES", null);

      if (res.status) {
        this.recordPaymentModalCtrl = false;
        if (!this.invoice.Payments) {
          this.invoice.Payments = [];
        }
        this.invoice.Payments.push(this.paymentRecord);

        this.paymentRecord = {
          name: "",
          amount: "",
          date: new Date().toISOString(),
          curremcy: "NGN",
        };
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
      this.makePayAction = false;
    },
    // async sendInvoice() {
    //   this.isLoading = true;
    //   await this.$store.dispatch("SEND_INVOICE", this.invoice);
    //   const res = this.$store.getters["GET_INVOICE_RES"];

    //   this.$store.commit("SET_UP_INVOICE_RES", null);

    //   if (res.status) {
    //     alert(res.message);
    //   } else {
    //     alert(res.message);
    //   }
    //   this.isLoading = false;
    // },
    gotoCustomerDetails() {
      SAVE_LOCAL_DB_DATA("customer-details", {
        ...this.customer,
        routeFrom: "Invoice Details",
      });
      this.$router.push({ name: "CustomerDetails" });
    },
    editInvoice() {
      SAVE_LOCAL_DB_DATA("invoice-details", this.invoice);
      this.$router.push({ name: "AddInvoice" });
    },
    async deleteInvoice() {
      if (confirm("Are you sure you want to delete this invoice?")) {
        this.isLoading = true;
        await this.$store.dispatch("DELETE_INVOICE", this.invoice);

        const res = this.$store.getters["GET_INVOICE_RES"];

        this.$store.commit("SET_UP_INVOICE_RES", null);

        if (res.status) {
          this.$router.back();
        } else {
          // alert(res.error);
          eventBus.$emit("trac-alert", { message: res.error });
        }
        this.isLoading = false;
      }
    },
    openRequestPayment() {
      this.requestPaymentModalCtrl = true;
    },
    getBusinessDetails() {
      this.currentUserBusinessDetails = GET_USER_BUSINESS_DATA();
      this.authAgentDetails = GET_USER_DATA();
    },
    changeFulfillmentValue: function (newValue) {
      this.invoice.order_status = newValue;
    },
    async fetchInvoiceDetails() {
      // Fetch invoice preview
      const httpReq = {
        url: `/invoices/${this.invoice._id}`,
        method: "GET",
        authRequest: false,
      };

      const httpRes = await HTTP_REQUEST(httpReq);

      this.invoicePreview = httpRes.data;
    },
    async fetchInvoice(invoiceID) {
      await this.$store.dispatch("FETCH_INVOICE", invoiceID);
      const res = this.$store.getters["GET_INVOICE_RES"];

      this.$store.commit("SET_UP_ALL_INVOICES", null);

      if (res.status) {
        // this.invoice = res.data.invoice;
        if (this.customers) {
          this.customer = this.customers.find(
            (cust) => cust._id === this.invoice.client
          );
        }
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
    },
    // Fetching the customer details
    async collateCustomerBalance() {
      await this.$store.dispatch("FETCH_ALL_INVOICES");
      let invoices = this.$store.getters["GET_ALL_INVOICES"].data || [];

      invoices = invoices.invoice ? invoices.invoice : [];

      this.customers.forEach((customer) => {
        customer.balance = 0;
        // Loop through all invoices in the business
        invoices.forEach((invoice) => {
          if (customer._id === invoice.client) {
            customer.balance +=
              isNaN(+invoice.balance.outstanding.amount) ||
              +invoice.balance.outstanding.amount === undefined ||
              +invoice.balance.outstanding.amount === null
                ? 0
                : +invoice.balance.outstanding.amount;
          }
        });
      });
    },
    async fetchAllCustomers() {
      // Fetch all customers
      await this.$store.dispatch("FETCH_ALL_CUSTOMERS");
      const res = this.$store.getters["GET_ALL_CUSTOMERS"];

      if (res.status) {
        const customersData = res.data ? res.data.items || [] : [];
        this.customers = customersData;
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
    },
  },
};
</script>

<style scoped>
.business-logo {
  max-height: 200px;
}
</style>
